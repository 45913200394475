import Backbone from 'backbone';
import Context from 'context';
import Tag from 'models/tag';
import Workspaces from 'collections/workspaces';
import { backboneModelComparator } from 'utils/compareUtils';
import type {
  ComponentBackboneModel,
  Reference,
  Tag as TagBackboneModel,
  Workspace,
} from 'aqTypes';
import { getApiUrl } from 'backboneExtensions';
import { APITagAttributes, ArdoqId } from '@ardoq/api-types';
import { subscribeToAction } from 'streams/utils/streamUtils';
import {
  notifySortChanged,
  notifyWorkspaceClosed,
} from 'streams/context/ContextActions';
import { delay, each, filter, includes } from 'lodash';

export class Tags extends Backbone.Collection<TagBackboneModel> {
  model = Tag.model;
  url = `${getApiUrl(Backbone.Collection)}/api/tag`;
  comparator = backboneModelComparator;
  constructor(props?: Record<string, any>[]) {
    super(props);
    subscribeToAction(notifyWorkspaceClosed, ({ workspaceId }) => {
      this.saveAllChangedModels();

      this.remove(
        this.filter(function (tag) {
          return tag.get('rootWorkspace') === workspaceId;
        }),
        {
          silent: true,
        }
      );
    });
    subscribeToAction(notifySortChanged, () => this.sort());
  }
  tagEntity(tag: string, entity: ComponentBackboneModel | Reference) {
    let foundTag = this.getByName(tag, entity.get('rootWorkspace'));
    if (!foundTag) {
      foundTag = Tag.create({
        name: tag,
        rootWorkspace: entity.get('rootWorkspace'),
      });
      this.add(foundTag);
    }
    if (entity) {
      foundTag!.tagEntity(entity);
    }
    return foundTag;
  }

  getWorkspaceTags(rootWorkspace: ArdoqId) {
    return this.where({
      rootWorkspace,
    });
  }
  getTagsByModel(id: ArdoqId) {
    return this.filter(function (tag) {
      return includes(
        tag.getIdsOfComponentsWithTag().concat(tag.getIdsOfReferencesWithTag()),
        id
      );
    });
  }
  saveAllChangedModels() {
    let i = 0;
    each(this.models, function (tag) {
      if (tag.changedAndMustBeSaved()) {
        const w = Workspaces.collection.get(tag.get('rootWorkspace'));
        if (w && w.hasWriteAccess()) {
          delay(function () {
            tag.save().catch((validationErrors: string[]) => {
              tag.mustBeSaved = false;
              if (!tag.isClientSideValid()) {
                let message = `${tag.name()} has validation errors - please fix it:\n`;
                each(validationErrors, function (m, k) {
                  message += `${k}: ${m}`;
                });
                alert(message);
              }
            });
          }, 100 * i++);
        }
      }
    });
  }
  getByName(tagName: string, workspaceId: ArdoqId) {
    if (!tagName) return null;

    return filter(this.getWorkspaceTags(workspaceId), function (tag) {
      return tag.name().toLowerCase() === tagName.toLowerCase();
    })[0];
  }
  loadTags(tags: APITagAttributes[]) {
    const contextWorkspaces: Record<string, Workspace> = {};
    Context.workspaces().forEach(ws => {
      contextWorkspaces[ws.id] = ws;
    });
    tags.forEach(attributes => {
      const ws = contextWorkspaces[attributes.rootWorkspace];
      if (ws) {
        const tag = this.add(Tag.create(attributes));
        tag.fetch();
      }
    });
  }
}

function createTags(props: Record<string, any>[]) {
  return new Tags(props);
}

const globalCollection = new Tags();

export default {
  collection: globalCollection,
  create: createTags,
};
